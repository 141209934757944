import { configureStore } from '@reduxjs/toolkit';
import serviceOrderReducer from '../store/serviceOrderSlice';
import serviceCatalogReducer from '../store/serviceCatalogSlice';
import serviceInstanceReducer from '../store/serviceInstanceSlice';
import userReducer from '../store/userSlice';
import createNewServiceOrderReducer from '../store/createNewServiceOrder';
import digitalProviderReducer from '../store/digitalProvider';
// Import your slices here

const store = configureStore({
  reducer: {
    serviceOrder: serviceOrderReducer,
    serviceCatalog: serviceCatalogReducer,
    user: userReducer,
    serviceInstance: serviceInstanceReducer,
    createNewServiceOrder: createNewServiceOrderReducer,
    digitalProvider: digitalProviderReducer
  },
});

export default store;