import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../lib/axios';

const statesToMatch = ['acknowledged', 'pending', 'inProgress', 'partial'];
// Async thunk to fetch service orders
export const fetchServiceOrders = createAsyncThunk(
  'serviceOrder/fetchServiceOrders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('serviceorder/serviceOrder'); // Replace with your actual API endpoint
      return response.data; // Assuming the API returns the data in the 'body' key
    } catch (error) {
      if (!error.response) {
        // If there's no response (e.g., network error)
        throw new Error('Network error: Failed to connect to the server');
      } else {        
        return rejectWithValue(error?.response?.data);
      }
      // let jsonString = error.response?.data.replace(/'/g, '"');
      // let errorObject = JSON.parse(jsonString);
      // return rejectWithValue(errorObject || 'Failed to fetch service orders');
    }
  }
);

// Async thunk to fetch service instance details
export const fetchServiceOrderByID = createAsyncThunk(
  'serviceInstance/fetchServiceOrderByID',
  async (selectedInstance, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/serviceorder/serviceOrder/${selectedInstance}`);      
      if (response?.data?.statusCode === 403) {
        return rejectWithValue(response.data.error);
      }
      return response.data.body;

    } catch (error) {
      let jsonString = error.response?.data.replace(/'/g, '"');
      let errorObject = JSON.parse(jsonString);
      return rejectWithValue(errorObject || 'Failed to fetch service Order');
    }
  }
);

// Async thunk to create a new service order
export const createNewServiceOrder = createAsyncThunk(
  'serviceOrder/createNewServiceOrder',
  async (serviceOrderData, { rejectWithValue }) => {
    try {      
      const response = await axiosInstance.post('serviceorder/serviceOrder', serviceOrderData);            
      return response.data;
    } catch (error) {
      if (!error.response) {
        // Handle network error (no response from server)
        throw new Error('Network error: Failed to connect to the server');
      } else {
        console.log(error, 'error');
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

const serviceOrderSlice = createSlice({
  name: 'serviceOrder',
  initialState: {
    serviceOrderList: [],
    loading: false,
    serviceOrderCount: 0,
    createNewOrder: {},
    createLoading: false, // Separate loading state for create API call
    error: {},
    createError: null,
  },
  reducers: {
    // Add synchronous reducers if needed
  },
  extraReducers: (builder) => {
    // Handle fetchServiceOrders
    builder
      .addCase(fetchServiceOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceOrders.fulfilled, (state, action) => {
        if(action.payload?.statusCode === 404 || action.payload?.statusCode === 401 || action.payload?.statusCode === 403 ){
          const error = {
            ...action.payload?.error,
            severity: "warning"
          }
          state.error = error || { message: 'Failed to fetch service orders'};
        } else if (action.payload?.body && action.payload?.body.length === 0) {
          state.error = {
            message: "No data found",
            reason: "Item Not Found",
            severity: "warning"
          };
        } 
        else {
          state.serviceOrderList = action.payload.body;
          state.error = null;
          state.serviceOrderCount = action.payload?.body?.filter(order => 
            statesToMatch.includes(order.state)
          ).length || 0;
          
        }
        state.loading = false;
      })
      .addCase(fetchServiceOrders.rejected, (state, action) => {
        state.error = { ...action.payload?.error,
          severity: "error"} || {
          message: "No data found",
          reason: "Item Not Found",
          severity: "error"};
        state.loading = false;
      });

    // Handle createNewServiceOrder
    builder
      .addCase(createNewServiceOrder.pending, (state) => {
        state.createLoading = true;
        state.createError = null;
      })
      .addCase(createNewServiceOrder.fulfilled, (state, action) => {
        state.createNewOrder = action.payload;
        state.createLoading = false;
      })
      .addCase(createNewServiceOrder.rejected, (state, action) => {
        state.createError = { ...action.payload?.error,
          severity: "error"} || {
          message: "No data found",
          reason: "Item Not Found",
          severity: "error"};
        state.createLoading = false;
        
      });
  },
});

// Export the slice reducer
export default serviceOrderSlice.reducer;
