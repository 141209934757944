import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Select, MenuItem, FormControl } from '@mui/material';

const Header = ({changeLanguage}) => {

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#e20074'}}>
      <Toolbar sx={{ marginLeft: { xs: 0, md: '10rem' }, marginRight: { xs: 0, md: '8rem' }, height: '80px'}}>
        {/* Logo on the left */}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <img src="assets/images/T-SDBH.png" alt="Logo" style={{ height: 40 }} />
        </Typography>
        

        {/* Menu on the right */}
        <Box sx={{ display: 'flex' }} >
          <FormControl variant="outlined" size="small">
            <Select
              defaultValue="en"
              sx={{ backgroundColor: 'white'}}
              onChange={(e) => changeLanguage(e.target.value)}
            >
              <MenuItem selected value="en">EN</MenuItem>
              <MenuItem disabled value="de">DE</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
