import axios from 'axios';
const ClientId = process.env.REACT_APP_CLIENT_ID;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // 'Content-Type': 'application/x-amz-json-1.1',
    // 'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
  },
});

// Add a request interceptor to add the access token to each request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = token;
      // config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration and refresh logic
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem('refreshToken');
    // console.log("Calling", error, originalRequest._retry, axios.isAxiosError(error));
    const params = {
          AuthFlow: "REFRESH_TOKEN_AUTH",
          AuthParameters : {
              grant_type: "REFRESH_TOKEN",
              REFRESH_TOKEN: refreshToken,
          },
          ClientId: ClientId,
        }
    if ((error.response.status === 401 || error.response.status === 500) && !originalRequest._retry && refreshToken) {
      originalRequest._retry = true;
      try {
        const { data } = await axios.post(process.env.REACT_APP_API_LOGIN_URL, params, {
          headers: {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',}
        });
        localStorage.setItem('accessToken', data.AuthenticationResult.AccessToken);
        // localStorage.setItem('refreshToken', data.AuthenticationResult.refreshToken);

        // Update the Authorization header with the new access token
        axiosInstance.defaults.headers.common['Authorization'] = data.accessToken;
        originalRequest.headers['Authorization'] = data.accessToken;
        // axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
        // originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;

        // Retry the original request with the new token
        return axiosInstance(originalRequest);
      } catch (err) {
        // Handle refresh token failure (e.g., logout the user)
        console.error('Refresh token failed', err);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        // window.location.href = '/';
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
