import React from "react";
import RadioUnChecked from '@mui/icons-material/RadioButtonUnchecked';
import RadioChecked from '@mui/icons-material/RadioButtonChecked';
import MaterialReactTable, {
  MRT_GlobalFilterTextField as MRTGlobalFilterTextField,
  MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
  MRT_ToggleFiltersButton as MRTToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
  MRT_ToolbarAlertBanner as MRTToolbarAlertBanner
} from 'material-react-table';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box } from "@mui/material";

export function CSCheckBoxIcon(condition) {
  return condition ? <RadioChecked fontSize="small" /> : <RadioUnChecked fontSize="small" />;
}

const DataTable = (props) => {

  const theme = createTheme({
    palette: {
      magenta: {
        main: '#e20074'
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MaterialReactTable
        {...props}
        enableFullScreenToggle={false}
        enableColumnResizing={true}
        columnResizeDirection='rtl'
        enableDensityToggle={true}
        initialState={{
          ...props.initialState,
          density: 'compact',
        }}
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTableHeadCellProps={{
          ...props.muiTableHeadCellProps,
          sx: {
            fontWeight: '600',
            color:'black',
            // display: 'flex-start',
            justifyContent: 'space-between',
            alignItems: 'row-reverse',
            fontSize: '14px',
            paddingRight: 0,
            backgroundColor: '#D3D3D3',
            // backgroundColor: '#f5f5f5',
            '& .Mui-TableHeadCell-Content': {
              justifyContent: 'space-between',
            },
          },
        }}
        muiSortLabelProps={{
          sx: {
            color: 'black',  // Apply custom color
            '&:hover': {
              color: 'secondary.main', // Change color on hover
            },
            '&.Mui-active': {
              color: '#d500f9', // Color when sorting is active
            },
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontSize: '14px',
            paddingLeft: '2px',
          },
        }}
        state={{ ...props.state }}
        isTopToolbar={true}
        muiLinearProgressProps={({ isTopToolbar }) => ({
          color: 'magenta',
          sx: { display: isTopToolbar ? 'block' : 'none' }
        })}
        globalFilterFn="contains"
        renderTopToolbar={({ table }) => (
          <>
            <Box sx={{ display: props.positionToolbarAlertBanner === "top" ? "block" : "none" }}>
              <MRTToolbarAlertBanner stackAlertBanner table={table} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', margin: '10px' }}>
                {props.tableTitleComponent}
              </Box>
              <MRTGlobalFilterTextField table={table} />
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <MRTToggleGlobalFilterButton table={table} />
                <MRTToggleFiltersButton table={table} />
                <MRTShowHideColumnsButton table={table} />
              </Box>
            </Box>
          </>
        )}
        
      />
    </ThemeProvider>
  );
};

export default DataTable;
