import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../lib/axios';

// Async thunk to fetch service orders after user authentication
export const fetchServiceCatalogs = createAsyncThunk(
  'serviceCatalog/fetchServiceCatalogs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('catalog/serviceCatalog'); // Replace with your actual API endpoint
      return response.data;
    } catch (error) {
      let jsonString = error.response?.data.replace(/'/g, '"');
      let errorObject = JSON.parse(jsonString);
      return rejectWithValue(errorObject || 'Failed to fetch Service Catalogues');
    }
  }
);

// Async thunk to fetch service instance details
export const fetchServiceCatalogByID = createAsyncThunk(
  'serviceInstance/fetchServiceCatalogByID',
  async (selectedInstance, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`catalog/serviceCatalog/${selectedInstance}`);      
      if (response?.data?.statusCode === 403) {
        return rejectWithValue(response.data.error);
      }
      return response.data.body;

    } catch (error) {
      let jsonString = error.response?.data.replace(/'/g, '"');
      let errorObject = JSON.parse(jsonString);
      return rejectWithValue(errorObject || 'Failed to fetch Service Catalogue');
    }
  }
);

const serviceCatalogSlice = createSlice({
  name: 'serviceCatalog',
  initialState: {
    serviceCatalogData: [],
    enabledCatalogs: [],
    loading: false,
    catalogError: null,
    serviceCatalogById: [],
    catalogueDeliveryPoints: [],
    networkData: [],
    serviceTypes:[],
  },
  reducers: {
    // You can add any synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceCatalogs.pending, (state) => {
        state.loading = true;
        state.catalogError = null;
      })
      .addCase(fetchServiceCatalogs.fulfilled, (state, action) => {
        if (action.payload?.statusCode === 404 || action.payload?.statusCode === 401 || action.payload?.statusCode === 403) {
          const error = {
            ...action.payload?.error,
            severity: "warning"
          }
          state.catalogError = error || { message: 'Failed to fetch service Instances'};
        } else if (action.payload?.body && action.payload?.body.length === 0) {
          state.catalogError = {
            message: "No data found",
            reason: "Item Not Found",
            severity: "warning"
          };
        } else {
          const catalog = action.payload?.body
          state.serviceCatalogData = action.payload?.body;
          state.enabledCatalogs = action.payload?.body?.filter(
            (catalog) => catalog.state === "enabled" && catalog.nfSpecification.nfState === "enabled"
          );
          state.serviceTypes = [...new Set(
            catalog
              .map(service => service.service_type)
          )];
          state.loading = false;
        }
      })
      .addCase(fetchServiceCatalogs.rejected, (state, action) => {
        state.catalogError = action.payload?.error || {
          message: "No data found",
          reason: "Item Not Found",
          severity: "error"
        };
        state.loading = false;
      });

    // Handle fetchServiceCatalogByID lifecycle
    builder
    .addCase(fetchServiceCatalogByID.pending, (state) => {
      state.loading = true;
      state.catalogError = null;
    })
    .addCase(fetchServiceCatalogByID.fulfilled, (state, action) => {
      // Extract serviceCatalogById with all top-level fields including csrProject
      state.serviceCatalogById = action.payload;
      state.catalogueDeliveryPoints = action.payload[0].deliveryPoint;
      state.networkData = action.payload[0].nfSpecification;
      state.loading = false;
    })
    .addCase(fetchServiceCatalogByID.rejected, (state, action) => {
      state.catalogError = action.payload || { message: 'Failed to fetch service order details' };
      state.loading = false;
    });
  },
});

// Export the async thunk and the slice reducer
export default serviceCatalogSlice.reducer;
