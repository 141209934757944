// Simple validators for different types
const isValid = {
    string: (value) => typeof value === 'string' && value.trim() !== '',
    number: (value) => typeof value === 'number' && value > 0
  };
  
  // Required fields for each step
  const stepRequirements = {
    0: ['category'],
    1: {
      basicFields: ['serviceType', 'name'],
      characteristics: {
        required: [
          'provider',
          'csrProject',
          'infraService',
          'location',
          'internetAccess_type'
        ],
        numerical: [
          'intranetAccess_zVLan',
          'intranetAccess_bandwidth'
        ],
        conditional: [
          'intranetAccess_zPortId',
        ]
      }
    }
  };
  
  export const useServiceOrderValidation = (formData, activeStep) => {
    const validateStep = () => {
      const service = formData?.serviceOrderItem?.[0]?.service;
  
      switch (activeStep) {
        case 0:
          return isValid.string(formData.category);
  
        case 1:
          // Check basic fields (serviceType and name)
          if (!stepRequirements[1].basicFields.every(field => 
            isValid.string(service?.[field]))) {
            return false;
          }
  
          const characteristics = service?.serviceCharacteristic || [];
  
          // Required string fields
          const requiredValid = stepRequirements[1].characteristics.required.every(field => {
            const char = characteristics.find(c => c.name === field);
            return isValid.string(char?.value);
          });
  
          if (!requiredValid) return false;
  
          // Numerical fields
          const numericalValid = stepRequirements[1].characteristics.numerical.every(field => {
            const char = characteristics.find(c => c.name === field);
            return isValid.number(char?.value);
          });
  
          if (!numericalValid) return false;
  
          // Conditional fields based on service type
          const conditionalValid = stepRequirements[1].characteristics.conditional.every(field => {
            const char = characteristics.find(c => c.name === field);
            return isValid.string(char?.value);
          });
  
          return conditionalValid;
  
        case 2:
          return true; // Add validation if needed
  
        case 3:
          return false; // Typically disabled in submission step
  
        default:
          return true;
      }
    };
  
    return !validateStep();
  };