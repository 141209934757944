import React, { Fragment, useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import axiosInstance from '../lib/axios';
import Header from './global/Header';
import Background from '../assets/images/loginBg.png';

const Login = ({ changeLanguage, setIsAuthenticated}) => {
  const ClientId = process.env.REACT_APP_CLIENT_ID;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  const isFormValid = email && password && !errors.email && !errors.password;

  const validateEmail = (email) => {
    // Basic email validation regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!value) {
      setErrors((prev) => ({ ...prev, email: 'Email is required' }));
    } else if (!validateEmail(value)) {
      setErrors((prev) => ({ ...prev, email: 'Enter a valid email' }));
    } else {
      setErrors((prev) => ({ ...prev, email: '' }));
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (!value) {
      setErrors((prev) => ({ ...prev, password: 'Password is required' }));
    } else if (value.length < 5) {
      setErrors((prev) => ({ ...prev, password: 'Password must be at least 5 characters' }));
    } else {
      setErrors((prev) => ({ ...prev, password: '' }));
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
      
    if (isFormValid) {
        const params = {
            AuthFlow: "USER_PASSWORD_AUTH",
            AuthParameters: {
                USERNAME: email,
                PASSWORD: password,
            },
            ClientId: ClientId,
        }
        try {
            const response = await axiosInstance.post(process.env.REACT_APP_API_LOGIN_URL, 
              params, {
              headers: {
              'Content-Type': 'application/x-amz-json-1.1',
              'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',}
            });

            const data = await response.data;
            // Save the JWT token in local storage
            localStorage.setItem('accessToken', data.AuthenticationResult.AccessToken);
            localStorage.setItem('refreshToken', data.AuthenticationResult.RefreshToken);
            localStorage.setItem('loginTimestamp', Date.now());
            localStorage.setItem('userEmail', email);
            setIsAuthenticated(true)
            navigate('/dashboard');
        } catch (err) {
            // Handle errors
            if (err.response) {
              // Server responded with a status other than 200 range
              setError(`${err.response.data.message || err.response.statusText}`);
            } else if (err.request) {
              // Request was made but no response was received
              setError('No response received from the server.');
            } else {
              // Something happened in setting up the request
              setError(`${err.message}`);
            }
        }
    }
    
  };

  return (
    <Fragment>
      <Header changeLanguage={changeLanguage} />
      <div style={{ backgroundPosition: 'right', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', backgroundImage: `url(${Background})`, marginTop: '60px', minHeight: '70vh', w: '100%',justifyContent: 'center',display: 'flex', alignItems: 'center'}}>
        <div style={{  justifyContent: 'center', textAlign: 'center', alignItems: 'center',  marginBottom: '10rem', marginTop: 30}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 1,
                }}
            >
                <img
                    src="assets/images/SDBH.png" // Replace with your logo URL
                    alt="Brand Logo"
                    style={{ width: 100, height: 120 }}
                />
                <Typography variant="h1" sx={{ fontSize: '2rem', textShadow: '1px #fff', fontWeight: 600, color: "#e20074", marginBottom: 4 }}>
                  Software Defined Business Hub
                </Typography>
            </Box>
            
            <Box
                component="form"
                onSubmit={handleLogin}
                sx={{
                    maxWidth: {xs : 320, md: 400},
                    margin: 'auto',
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    boxShadow: 3,
                    borderRadius: '10px',
                }}
            >

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <LockIcon sx={{ fontSize: 18, color: 'white', backgroundColor: 'secondary.main', borderRadius: '100%', border: 1, padding: '0.4rem' }} />
                </Box>
                <Typography variant="h4" sx={{ fontSize: '1.6rem'}} align="center">
                    Sign In
                </Typography>
                
                <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    required
                    value={email}
                    onChange={handleEmailChange}
                    error={!!errors.email}
                    helperText={errors.email}
                />
                <TextField
                    required
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    error={!!errors.password}
                    helperText={errors.password}
                />
                {error && <Typography variant='body2' color="error">{error}</Typography>}

                {/* <FormControlLabel
                    size="small"
                    control={
                    <Checkbox
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                        color="primary"
                        size="caption"
                    />
                    }
                    label="Remember Me"
                    sx={{
                        paddingLeft: 1,
                        '& .MuiTypography-root': {
                        fontSize: '0.8rem',
                        fontWeight: 500
                        },
                    }}
                /> */}
                <Button type="submit" disabled={!isFormValid} variant="contained" color="secondary" fullWidth>
                    Sign In
                </Button>

                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <Link href="#forgot-password" variant="caption">
                    Forgot Password?
                    </Link>
                    <Link href="#sign-up" variant="caption">
                    Don't have an account? Sign Up
                    </Link>
                </Box> */}
            </Box>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
