import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import PropTypes from 'prop-types';

const AlertComponent = ({ severity = 'info', title, description }) => (
  <Alert severity={severity}>
    {title && <AlertTitle>{title}</AlertTitle>}
    {description}
  </Alert>
);

AlertComponent.propTypes = {
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  title: PropTypes.string,
  description: PropTypes.string
};

export default AlertComponent;