import React from 'react';
import { Stepper, Step, StepLabel, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomStepLabel = styled(StepLabel)({
    '.MuiStepIcon-root': {
      color: '#e0e0e0', // Default color
    },
    '.MuiStepIcon-root.Mui-active': {
      color: '#e20074', // Active step color
    },
    '.MuiStepIcon-root.Mui-completed': {
      color: '#4caf50', // Completed step color
    },
  });

const StepsComponent = ({ steps, activeStep}) => {
  return (
    <Box sx={{ m: 'auto', mb:2 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
          <Step key={label}>
              <CustomStepLabel>{label}</CustomStepLabel>
          </Step>
          ))}
        </Stepper>
    </Box>
  )
};

export default StepsComponent;