import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

const Footer = () => {
  return (
    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: '#191919'}}>
      <Toolbar sx={{ justifyContent: 'space-between', marginLeft: { xs: 0, md: '10rem' }, marginRight: { xs: 0, md: '8rem' } }}>
        {/* Logo on the left */}
        <Box sx={{ display: { md: 'flex'}, alignItems: 'center' }}>
          <img src="assets/images/T_logo_rgb_n.svg" alt="Logo" style={{ height: 20, marginRight: '10px' }} />
          <Typography variant="body2">© 2024 Deutsche Telekom AG</Typography>
        </Box>

        {/* Menu on the right */}
        <Box variant="body2" sx={{ display: 'flex' }}>
            <MenuItem color='primary' sx={{  fontSize: 14}} component="a" href="mailto:SDBH_Development@telekom.de?subject=FMB%20SDBH%20Development">Contact Us</MenuItem>
            {/* <MenuItem sx={{  fontSize: 14}} component="a" href="#terms">Terms of Use</MenuItem>
            <MenuItem sx={{  fontSize: 14}} component="a" href="#contact">Legal Notice</MenuItem>
            <MenuItem sx={{  fontSize: 14}} component="a" href="#privacy">Privacy Policy</MenuItem> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
