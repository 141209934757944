import React from 'react';
import { Grid, Card, CardContent, Typography, Box, CircularProgress } from '@mui/material';
import DynamicIcon from './global/DynamicIcon';
import AlertComponent from './global/AlertComponent';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const DashboardComponent = () => {
  const { projectCount, organizationCount, userError } = useSelector((state) => state.user);
  const { serviceInstanceCount } = useSelector((state) => state.serviceInstance);
  const { serviceOrderCount, loading } = useSelector((state) => state.serviceOrder);

  const data = [
    { id: 1, icon: 'AccountBalance', title: 'My Project Groups', count: organizationCount, link: '/userDetails' },
    { id: 2, icon: 'BusinessCenter', title: 'My Projects', count: projectCount, link: '/userDetails' },
    { id: 3, icon: 'Widgets', title: 'Service Instances', count: serviceInstanceCount, link: '/serviceinstance' },
    { id: 4, icon: 'ShoppingCart', title: 'Pending Service Orders', count: serviceOrderCount, link: '/serviceOrder' },
  ];

  if (loading) {
    return <Box sx={{ padding: '2rem', ml: '1px'}}>
      <CircularProgress color='secondary' />
    </Box>;
  }

  return (
    <Box>
      {userError?.reason ? (
        <AlertComponent
          fullWidth
          severity={userError?.severity}
          title={userError?.reason}
          description={userError?.message}
        />
      ) : (
        <Box>
          <AlertComponent
            severity="info"
            title="Welcome to Software Defined Business Hub"
            description=""
          />
          <Box sx={{ padding: '2rem' }} justifyContent="center" alignContent="center" display="flex">
            <Grid container rowSpacing={4} sx={{ maxWidth: '750px' }}>
              {data.map((item) => (
                <Grid item xs={12} sm={6} md={6} key={item.id}>
                  <Typography align='center' fontWeight={600} variant="h6">{item.title}</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Card
                      sx={{
                        bgcolor: 'primary.main',
                        color: 'white',
                        height: '200px', // square height
                        width: '250px',  // square width
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      {item.link ? (
                        <Link to={item.link} style={{ color: 'inherit', textDecoration: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                          <CardContent sx={{ p: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <DynamicIcon iconName={item.icon} fontSize="large" color="white" />
                            <Typography variant="h3" fontWeight="500" sx={{ pt: 1 }}>{String(item.count)}</Typography>
                          </CardContent>
                        </Link>
                      ) : (
                        <CardContent sx={{ p: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                          <DynamicIcon iconName={item.icon} fontSize="large" color="white" />
                          <Typography variant="h3" fontWeight="500" sx={{ pt: 1 }}>{String(item.count)}</Typography>
                        </CardContent>
                      )}
                    </Card>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DashboardComponent;