import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, MenuItem, Grid } from '@mui/material';
import { setFormData } from '../../../store/createNewServiceOrder';

const CreateServiceOrderStep = ({ selectedStartDate, setSelectedCategory }) => {
  const dispatch = useDispatch();
  // State to store the selected category
  
  const { formData } = useSelector((state) => state.createNewServiceOrder);
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000); // Add 3 days
  futureDate.setUTCHours(23, 59, 0, 0); // Set time to 23:59:00.000 in UTC
  // const RequestedEndDate = futureDate.toISOString();
  // const RequestedEndDate = new Date(new Date().setDate(new Date().getDate() + 3)).toISOString().split('T')[0] + "T23:59:00.000Z";
  // const [initialRequestedEndDate, setInitialRequestedEndDate] = useState(RequestedEndDate);
   // Extract unique categories
  //  const categories = [...new Set(enabledCatalogs.map(service => service.category))];

  // Function to format the start date in UTC format
  // const formatDateToUTC = (date, time) => {
  //   if (!date) return ''; // Return empty string if the date is null
  //   return formatInTimeZone(
  //     date,
  //     'UTC',
  //     `yyyy-MM-dd'T'${time}.SSS'Z'`
  //   );
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if(name === 'category') {
      setSelectedCategory(value);
    }
    
    dispatch(setFormData({
      ...formData,
      [name]: value,
    }));
  };
  

  // const handleDateChange = (date, fieldName) => {
  //   let futureDate = new Date(date + 3600000);
  //   let isoString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    
  //   let hours = futureDate.getUTCHours();
  //   if (fieldName === 'requestedStartDate') {
  //     dispatch(setFormData((prev) => ({
  //       ...prev,
  //       requestedStartDate: formatDateToUTC(isoString, `${hours}:00:00`),
  //     })));
  //     setSelectedStartDate(isoString);
  //       if (selectedEndDate && date && selectedEndDate < date) {
  //           setSelectedEndDate(null);
  //       }
  //       if(date) {
  //       } else {
  //         dispatch(setFormData((prev) => ({
  //           ...prev,
  //           requestedStartDate: today,
  //         })));
  //       }
      
  //   } else if (fieldName === 'requestedCompletionDate') {
  //     setSelectedEndDate(isoString);
  //     if(date) {
  //       dispatch(setFormData((prev) => ({
  //         ...prev,
  //         requestedCompletionDate: formatDateToUTC(isoString, '23:59:00'),
  //       })));
  //     } else {
  //       dispatch(setFormData((prev) => ({
  //         ...prev,
  //         requestedCompletionDate: RequestedEndDate,
  //       })));
  //     }
      
  //   }
  // };

  // useEffect(() => {
  //     if(selectedStartDate) {
  //       // const tomorrow = new Date(selectedStartDate);
  //       // setInitialRequestedEndDate(tomorrow.setDate(tomorrow.getDate() + 2))
  //     }
  // }, [selectedStartDate]);


  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          required
          select
          fullWidth
          margin="normal"
          label="Category"
          name="category"
          value={formData.category}
          InputLabelProps={{shrink: true}}
          onChange={handleInputChange}
        >
          <MenuItem value="PIU">PIU</MenuItem>
          <MenuItem value="MPLS-TE">MPLS-TE</MenuItem>
          <MenuItem value="SD-WAN">SD-WAN</MenuItem>
          <MenuItem value="VPN">VPN</MenuItem>
          {/* {categories.map((category, index) => (
          <MenuItem key={index} value={category}>
            {category}
          </MenuItem>
        ))} */}
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="normal"
          label="Order description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          InputLabelProps={{shrink: true}}
          placeholder='Order description'
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="normal"
          label="External Reference ID"
          name="externalId"
          value={formData.externalId}
          onChange={handleInputChange}
          InputLabelProps={{shrink: true}}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="normal"
          label="Notification contact"
          name="notificationContact"
          value={formData.notificationContact}
          onChange={handleInputChange}
          InputLabelProps={{shrink: true}}
          placeholder='Notification contact email (1 only)'
        />
      </Grid>

      {/* <Grid item xs={6} sx={{ mt: 3 }}>
        <DatePicker
          selected={selectedStartDate}
          onChange={(date) => handleDateChange(date, 'requestedStartDate')}
          className="date-picker"
          minDate={initialServiceDate}
          dateFormat="yyyy-MM-dd"
          customInput={
            <TextField
                label="Requested Order Start Date"
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                    <CalendarToday />
                    </InputAdornment>
                ),
                }}
                readOnly
            />}
          placeholderText="Requested Order Start Date"
        />
      </Grid>

      <Grid item xs={6} sx={{ mt: 3 }}>
        <DatePicker
          selected={selectedEndDate}
          onChange={(date) => handleDateChange(date, 'requestedCompletionDate')}
          minDate={initialRequestedEndDate}
          className="date-picker"
          dateFormat="yyyy-MM-dd"
          customInput={
            <TextField
                label="Requested Completion Date"
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                    <CalendarToday />
                    </InputAdornment>
                ),
                }}
                readOnly
            />}
          placeholderText="Requested Completion Date"
        />
      </Grid> */}
    </Grid>
  );
};

export default CreateServiceOrderStep;
