import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceCatalogs } from '../../store/serviceCatalogSlice';
import { Box, Typography, IconButton, CircularProgress, Grid, Button, Collapse } from '@mui/material';
import DataTable from '../global/DataTable';
import { Link } from 'react-router-dom';
import DynamicIcon from '../global/DynamicIcon';
import AlertComponent from '../global/AlertComponent';
import SearchFilters from './SearchFilter'

// Separate component for the table columns configuration
const useTableColumns = (userRole) => {
  return useMemo(
    () => [
      {
        accessorKey: 'service_catalog_uuid',
        header: 'UUID',
        filterable: true,
        minSize: 7,
        size: 15
      },
      {
        accessorKey: 'service_type',
        header: 'Service Type',
        filterable: true,
        sortable: true,
        minSize: 10,
        size: 20
      },
      {
        accessorKey: 'name',
        header: 'Name',
        filterable: true,
        minSize: 10,
        size: 20
      },
      {
        accessorKey: 'details',
        header: 'Details',
        enableColumnFilter: false,
        filtering: false,
        sortable: false,
        minSize: 5,
        size: 15,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <IconButton>
            <Link to={`/catalog/${row._valuesCache.service_catalog_uuid}`}>
              <DynamicIcon iconName="Visibility" fontSize="medium" sx={{ color: "#e20074", fontSize: '18px'}} />
            </Link>
          </IconButton>
        ),
      },
      {
        accessorKey: 'newService',
        header: 'New Service',
        filterable: false,
        sortable: false,
        minSize: 5,
        size: 15,
        enableColumnFilter: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Button disabled={userRole !== 'Admin' || row.original.state === 'disabled'}>
            <Link to="/createnew">
              <DynamicIcon
                iconName="LibraryAdd"
                sx={{
                  fontSize: '18px',
                  color: userRole !== 'Admin' || row.original.state === 'disabled'
                    ? '#00000042'
                    : "#e20074"
                }}
              />
            </Link>
          </Button>
        ),
      },
    ],
    [userRole]
  );
};

const initialFilters = {
  serviceType: '',
  nameFilter: '',
  idFilter: '',
  infrastructure: '',
  provider: '',
  location: '',
  deploymentFlavour: '',
  serviceTerm: '',
  bandwidth: 0
};

const ServiceCatalogComponent = () => {

  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state.user);
  const [searchCatalogue, setSearchCatalogue] = useState(false)
  const { catalogError, loading, serviceCatalogData = [] } = useSelector((state) => state.serviceCatalog || {});

  const [filters, setFilters] = useState(initialFilters);
  const [filteredServiceCatalogData, setFilteredServiceCatalogData] = useState(serviceCatalogData);
  const columns = useTableColumns(userRole);

  // Initialize filtered data when serviceCatalogData changes
  useEffect(() => {
    if (serviceCatalogData.length > 0) {
      setFilteredServiceCatalogData(serviceCatalogData);
    }
  }, [serviceCatalogData]);

  const resetFilters = () => {
    setFilters(initialFilters);
    setFilteredServiceCatalogData(serviceCatalogData); // Reset to full data on filter reset
  };

  const handleCatalogueSearch = () => {

    if (serviceCatalogData.length === 0) return;

    const filteredData = serviceCatalogData.filter(item => {
      // String matching conditions with partial search
      const matchesServiceType = !filters.serviceType || 
        item.service_type.toLowerCase().includes(filters.serviceType.toLowerCase());
      
      const matchesName = !filters.nameFilter || 
        item.name.toLowerCase().includes(filters.nameFilter.toLowerCase());
      
      const matchesId = !filters.idFilter || 
        item.service_catalog_uuid.toLowerCase().includes(filters.idFilter.toLowerCase());
      
      // Optional chaining and existence checks for nested objects
      const matchesProvider = !filters.provider || 
        item.deliveryPoint?.some(dp => 
          dp.provider?.toLowerCase().includes(filters.provider.toLowerCase())
        );
      
      const matchesInfrastructure = !filters.infrastructure || 
        item.deliveryPoint?.some(dp => 
          dp.infraService?.toLowerCase().includes(filters.infrastructure.toLowerCase())
        );
      
      const matchesLocation = !filters.location || 
        item.deliveryPoint?.some(dp => 
          dp.availableLocation?.toLowerCase().includes(filters.location.toLowerCase())
        );
      
      const matchesDeploymentFlavour = !filters.deploymentFlavour || 
        item.nfSpecification?.deploymentFlavour?.toLowerCase()
          .includes(filters.deploymentFlavour.toLowerCase());
      
      // Numeric comparisons with null/undefined checks
      const matchesServiceTerm = !filters.serviceTerm || 
        (item.minimum_service_lifetime != null && 
         Number(item.minimum_service_lifetime) >= Number(filters.serviceTerm));
      
      const matchesBandwidth = !filters.bandwidth || 
        (item.nfSpecification?.maxBandwidth != null && 
         Number(item.nfSpecification.maxBandwidth) >= Number(filters.bandwidth));
  
      return (
        matchesServiceType &&
        matchesName &&
        matchesId &&
        matchesProvider &&
        matchesInfrastructure &&
        matchesLocation &&
        matchesDeploymentFlavour &&
        matchesServiceTerm &&
        matchesBandwidth
      );
    });
    setFilteredServiceCatalogData(filteredData);
  }

  useEffect(() => {
    if (serviceCatalogData.length === 0) {
      dispatch(fetchServiceCatalogs());
    }
  }, [dispatch, serviceCatalogData.length]);

  const renderContent = () => {
    if (catalogError?.reason) {
      return (
        <AlertComponent
          fullWidth
          severity={catalogError.severity}
          title={catalogError.reason}
          description={catalogError.message}
        />
      );
    }

    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="secondary" />
        </Box>
      );
    }

    return (
      <DataTable
        columns={columns}
        data={filteredServiceCatalogData}
        enableStickyHeader
        getRowId={(row) => row.id}
        enableExpandAll={false}
        enableTopToolbar={false}
      />
    );
  };

  const handleOpenFilters = () => {
    setSearchCatalogue(true);
  };

  return (
    <Box>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ boxShadow: 3, borderRadius: 2, p: 2, overflow: 'hidden', }}>
          <Typography variant="h5" marginY={2}>Service Catalogue</Typography>

          <Box sx={{ display: searchCatalogue ? 'none' : 'flex', justifyContent: 'start', my: 1, gap: 2 }}>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              onClick={e => handleOpenFilters()}
            >
              Search Catalogue Item
            </Button>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Collapse in={searchCatalogue} timeout={600}>
              <SearchFilters searchCatalogue={searchCatalogue}
                setSearchCatalogue={setSearchCatalogue}
                setFilters={setFilters}
                filters={filters}
                resetFilters={resetFilters}
                handleCatalogueSearch={handleCatalogueSearch} 
              />
            </Collapse>
          </Box>
          {searchCatalogue &&
            <Typography variant='h6' sx={{ my: 1.5 }}>
              Matching Service Catalogue Items
            </Typography>}
          {renderContent()}
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ mt: 3, mb: 3 }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            disabled
            size="large"
          >
            Create New Catalogue item
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceCatalogComponent;