import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
  Grid,
  Divider,
  IconButton, Paper
} from '@mui/material';
import { fetchServiceInstanceByID } from '../../store/serviceInstanceSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DynamicIcon from '../global/DynamicIcon';
import AlertComponent from '../global/AlertComponent';
import { formatInTimeZone } from 'date-fns-tz';

const ServiceInstancesDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { serviceInstanceById = [], servicError, loading, serviceCharacteristic } = useSelector((state) => state.serviceInstance || {});
  // const [responseError, setResponseError] = useState({});

  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const formatDateTime = (date) => {    
    if (!date) return 'N/A';
    return `${formatInTimeZone(
      date,
      'UTC',
      `yyyy-MM-dd' hrs. 'HH:mm:ss`
    )} UTC` ;
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchServiceInstanceByID(id));
    }
  }, [dispatch, id]);

  // Handler functions for buttons
  const handleUpdateServiceInstance = () => {
    console.log("Update Service Order clicked");
  };

  const handleDeleteServiceInstance = () => {
    console.log("Delete Service Order clicked");
  };
  const handleModify = (row) => {
    console.log('Modify row:', row);
  };

  const renderValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value || 'N/A';
  };

  return (
    <Box>
      <Button sx={{ mb: 2 }} onClick={() => navigate(-1)} variant="outlined" color="secondary">
        <DynamicIcon iconName="ArrowBack" sx={{ fontSize: '18px' }} />
      </Button>
      {servicError && servicError?.message ? (
        <AlertComponent
          severity="error"
          title={servicError?.reason}
          description={servicError?.message}
        />
      ) : !loading ? (
        <Box sx={{m: 'auto', width: '95%', maxWidth: '1024px', textAlign: 'center' }}>
          <Box sx={{ 
              maxWidth: {
                xs: '100%', // Extra small screens
                sm: '600px', // Small screens
                md: '800px', // Medium screens
                lg: '1024px', // Large screens
              }, textAlign: 'center'}}>
            <Accordion expanded={expanded} onChange={handleChange} sx={{ borderRadius: 1 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Service Instance Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper} sx={{  borderRadius: 0 }}>
                  <Table sx={{ tableLayout: 'fixed' }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#D3D3D3' }}>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>Field</TableCell>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>Value</TableCell>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>Modify</TableCell>
                      </TableRow>
                    </TableHead>
                    {serviceInstanceById?.length > 0 && serviceInstanceById.map((value, index) => (
                      <TableBody sx={{ textAlign: 'center' }} key={index}>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">UUID</TableCell>
                          <TableCell>{renderValue(value.id)}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Name</TableCell>
                          <TableCell>{renderValue(value.name)}</TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ padding: 0 }}
                              disabled
                              onClick={() => handleModify(value.name)}
                            >
                              <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Customer Project</TableCell>
                          <TableCell>{renderValue(value.csrProject)}</TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ padding: 0 }}
                              disabled
                              onClick={() => handleModify(value.csrProject)}
                            >
                              <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Description</TableCell>
                          <TableCell>{renderValue(value.description)}</TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ padding: 0 }}
                              disabled
                              onClick={() => handleModify(value.description)}
                            >
                              <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Category</TableCell>
                          <TableCell>{renderValue(value.category)}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Service Type</TableCell>
                          <TableCell>{value.serviceType}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">State</TableCell>
                          <TableCell>{renderValue(value.state)}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Start Date</TableCell>
                          <TableCell>{formatDateTime(value.startDate)}</TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ padding: 0 }}
                              disabled
                              onClick={() => handleModify(value.startDate)}
                            >
                              <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">End Date</TableCell>
                          <TableCell>{formatDateTime(value.endDate)}</TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ padding: 0 }}
                              disabled
                              onClick={() => handleModify(value.description)}
                            >
                              <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Service Specification</TableCell>
                          <TableCell>{value.serviceSpecification?.id}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ borderRadius: 1 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Service Characteristics</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper} sx={{  borderRadius: 0 }}>
                  <Table sx={{ tableLayout: 'fixed' }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#D3D3D3' }}>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>General</TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>
                          <IconButton
                            sx={{ padding: 0 }}
                            disabled
                            onClick={() => handleModify('serviceCharacteristics')}
                          >
                            <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>provider</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.provider}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>infrastructure Service</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.infraService}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>location</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.location}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <TableContainer component={Paper} sx={{  borderRadius: 0 }}>
                  <Table sx={{ tableLayout: 'fixed' }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#D3D3D3' }}>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>Intranet Access</TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>
                          <IconButton
                            sx={{ padding: 0 }}
                            disabled
                            onClick={() => handleModify('Intranet')}
                          >
                            <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>zPortId</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.intranetAccess_zPortId}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>zVLan</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.intranetAccess_zVLan}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>bandwidth</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.intranetAccess_bandwidth}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>Service Provider Name</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.intranetAccess_serviceProviderName}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>Service Provider ID</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.intranetAccess_serviceProviderId}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>Port Location</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.intranetAccess_portLocation}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>IP</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.intranetAccess_ip}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <TableContainer component={Paper} sx={{  borderRadius: 0 }}>
                  <Table sx={{ tableLayout: 'fixed' }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#D3D3D3' }}>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>Internet Access</TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>
                          <IconButton
                            sx={{ padding: 0 }}
                            disabled
                            onClick={() => handleModify('Internet')}
                          >
                            <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>Type</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.internetAccess_type}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>IP</strong>
                        </TableCell >
                        <TableCell >
                          {serviceCharacteristic.internetAccess_ip}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    {serviceCharacteristic.internetAccess_type !== "default" ?
                      (
                        <TableBody>
                          <TableRow sx={{ textTransform: 'capitalize' }}>
                            <TableCell >
                              <strong>zPortId</strong>
                            </TableCell >
                            <TableCell >
                              {serviceCharacteristic.internetAccess_zPortId}
                            </TableCell>
                            <TableCell >
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ textTransform: 'capitalize' }}>
                            <TableCell >
                              <strong>zVLan</strong>
                            </TableCell >
                            <TableCell >
                              {serviceCharacteristic.internetAccess_zVLan}
                            </TableCell>
                            <TableCell >
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ textTransform: 'capitalize' }}>
                            <TableCell >
                              <strong>bandwidth</strong>
                            </TableCell >
                            <TableCell >
                              {serviceCharacteristic.internetAccess_bandwidth}
                            </TableCell>
                            <TableCell >
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ textTransform: 'capitalize' }}>
                            <TableCell >
                              <strong>Service Provider Name</strong>
                            </TableCell >
                            <TableCell >
                              {serviceCharacteristic.internetAccess_serviceProviderName}
                            </TableCell>
                            <TableCell >
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ textTransform: 'capitalize' }}>
                            <TableCell >
                              <strong>Service Provider ID</strong>
                            </TableCell >
                            <TableCell >
                              {serviceCharacteristic.internetAccess_serviceProviderId}
                            </TableCell>
                            <TableCell >
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ textTransform: 'capitalize' }}>
                            <TableCell >
                              <strong>Port Location</strong>
                            </TableCell >
                            <TableCell >
                              {serviceCharacteristic.internetAccess_portLocation}
                            </TableCell>
                            <TableCell >
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <></>
                      )}
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Grid container spacing={2} sx={{ mt: 3, mb: 3 }}>
              {/* Div 1: Update Service Order */}
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleUpdateServiceInstance}
                  disabled
                  size='large'
                >
                  Update Service Instance
                </Button>
              </Grid>

              {/* Div 2: Delete Service Order */}
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDeleteServiceInstance}
                  disabled
                  size='large'
                >
                  Delete Service Instance
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )
      }
    </Box >
  );
};

export default ServiceInstancesDetails;
