import React from 'react';
import ReactJson from 'react-json-view';
import { Typography, Box, Grid, Divider } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';

const VerifyOrder = ({ formData }) => {
    // const renderObjectAsTable = (obj) => (
    //   <TableContainer component={Paper} sx={{ minWidth: 650, borderRadius: 0 }}>
    //     <Table size="small" aria-label="a dense table">
    //       <TableHead>
    //         <TableRow sx={{ backgroundColor: '#D3D3D3'}}>
    //         <TableCell  sx={{ color: '#000', fontWeight: '600',fontSize: '14px'}}>Field</TableCell>
    //         <TableCell  sx={{ color: '#000', fontWeight: '600',fontSize: '14px'}}>Value</TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {Object.entries(obj).map(([key, value], index) => (
    //           (String(value)  !== '[object Object]') &&(
    //           <TableRow key={index}>
    //             <TableCell variant="subtitle1" sx={{ fontWeight: '600', textTransform: 'capitalize' }}>{key.trim().replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2')}</TableCell>
    //             <TableCell>{String(value) !== "" ? String(value) : '-'}</TableCell>
    //           </TableRow>
    //         )))}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    // );
  // Function for formatting the date and time
const formatDateTime = (date) => {
  if (!date) return 'N/A';
  return `${formatInTimeZone(
    date,
    'UTC',
    `yyyy-MM-dd' hrs. 'HH:mm:ss`
  )} UTC` ;
};
  return (
    <Box>
      <Box sx={{ padding: 3, boxShadow: 3, borderRadius: 2 }}>
        <Typography variant="h4" color="secondary" sx={{ fontWeight: '500' }} gutterBottom>
          Service Order Data Overview
        </Typography>
      
        {formData.serviceOrderItem.map((item, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            {/* Service Order General Information */}
            <Typography variant="h6" sx={{ p: 1, fontWeight: '600', fontSize: '16px', backgroundColor: '#f0f0f0', borderRadius: 1 }} gutterBottom>
              General Information
            </Typography>
            
            <Grid container spacing={2} sx={{ mt: 1, pl: 1 }}>
              <Grid item xs={6}>
                <Typography variant="body2"><strong>Category:</strong> {formData.category}</Typography>
              </Grid>
              {formData.description && (
                <Grid item xs={6}>
                  <Typography variant="body2"><strong>Description:</strong> {formData.description}</Typography>
                </Grid>
              )}
              {formData.externalId && (
                <Grid item xs={6}>
                  <Typography variant="body2"><strong>External ID:</strong> {formData.externalId}</Typography>
                </Grid>
              )}
              {formData.notificationContact && (
                <Grid item xs={6}>
                <Typography variant="body2"><strong>Notification Contact:</strong> {formData.notificationContact}</Typography>
              </Grid>
              )}
              <Grid item xs={6}>
                <Typography variant="body2">
                  <strong>Requested Order Start Date:</strong> {formatDateTime(formData.requestedStartDate)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2"><strong> Requested Completion Date:</strong> {formatDateTime(formData.requestedCompletionDate)}</Typography>
              </Grid>
            </Grid>
            
            <Divider sx={{ mt: 2, mb: 2 }} />
            
            {/* Service Information */}
            <Typography variant="h6" sx={{ p: 1, fontWeight: '600', backgroundColor: '#f0f0f0', borderRadius: 1 }} gutterBottom>
              Service Information
            </Typography>
            
            <Grid container spacing={2} sx={{mt: 1, pl: 1 }}>
              <Grid item xs={6}>
                <Typography variant="body2"><strong>Service Type:</strong> {item.service.serviceType}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2"><strong>Service Name:</strong> {item.service.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2"><strong>Start Date:</strong> {formatDateTime(item.service.startDate)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2"><strong>End Date:</strong> {formatDateTime(item.service.endDate)}</Typography>
              </Grid>
            </Grid>

            <Divider sx={{ mt: 2, mb: 2 }} />
            
            {/* Service Characteristic */}
            <Typography variant="h6" sx={{ p: 1, fontWeight: '600', backgroundColor: '#f0f0f0', borderRadius: 1 }} gutterBottom>
              Service Characteristic
            </Typography>
            
            <Grid container spacing={2} sx={{mt: 1, pl: 1 }}>
              {item.service.serviceCharacteristic.map((characteristic, i) => {
                if (characteristic.value && (
                  characteristic.name === 'provider' ||
                  characteristic.name === 'location' ||
                  characteristic.name === 'infraService' ||
                  characteristic.name === 'csrProject'
                )) {
                  return (
                    <Grid item xs={6} key={i}>
                      <Typography variant="body2" sx={{textTransform: 'capitalize'}}>
                        <strong>{characteristic.name.replace('infraService', 'infrastructure Service').replace('intranetAccess_', '').replace(/_/g, ' ')}:</strong> {characteristic.value}
                      </Typography>
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>

            <Divider sx={{ mt: 2, mb: 2 }} />
            
            {/* Intranet Access */}
            <Typography variant="h6" sx={{ p: 1, fontWeight: '600', backgroundColor: '#f0f0f0', borderRadius: 1 }} gutterBottom>
              Intranet Access
            </Typography>
            
            <Grid container spacing={2} sx={{mt: 1, pl: 1 }}>
              {item.service.serviceCharacteristic.map((characteristic, i) => {
                if (characteristic.value && (
                  characteristic.name === 'intranetAccess_type' ||
                  characteristic.name === 'intranetAccess_zPortId' ||
                  characteristic.name === 'intranetAccess_zVLan' ||
                  characteristic.name === 'intranetAccess_bandwidth' ||
                  characteristic.name === 'intranetAccess_serviceProviderId' ||
                  characteristic.name === 'intranetAccess_serviceProviderName' ||
                  characteristic.name === 'intranetAccess_portLocation'
                )) {
                  return (
                    <Grid item xs={6} key={i}>
                      <Typography variant="body2">
                        <strong>{characteristic.name.replace('intranetAccess_', '').replace(/_/g, ' ')}:</strong> {characteristic.value}
                      </Typography>
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
              
            <Divider sx={{ mt: 2, mb: 2 }} />
            
            {/* Internet Access */}
            <Typography variant="h6" sx={{ p: 1, fontWeight: '600', backgroundColor: '#f0f0f0', borderRadius: 1 }} gutterBottom>
              Internet Access
            </Typography>
            
            <Grid container spacing={2} sx={{ mt: 1,pl: 1 }}>
              {item.service.serviceCharacteristic.map((characteristic, i) => {
                if (characteristic.value && (
                  characteristic.name === 'internetAccess_type' ||
                  characteristic.name === 'internetAccess_zPortId' ||
                  characteristic.name === 'internetAccess_zVLan' ||
                  characteristic.name === 'internetAccess_bandwidth'
                )) {
                  return (
                    <Grid item xs={6} key={i}>
                      <Typography variant="body2">
                        <strong>{characteristic.name.replace('internetAccess_', '').replace(/_/g, ' ')}:</strong> {characteristic.value}
                      </Typography>
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>

          </Box>
        ))}

        <Typography variant="h4" sx={{ p: 1, fontWeight: '600', fontSize: '16px', backgroundColor: '#f0f0f0', borderRadius: 1 }} gutterBottom>
          JSON View
        </Typography>
        <ReactJson src={formData} name={null} style={{ fontSize : 13 }} collapsed={true} indentWidth={3} displayDataTypes={false} displayObjectSize={false} />
      </Box>
  </Box>
  );
};

export default VerifyOrder;
