import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Grid, Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, IconButton, Tooltip } from '@mui/material';
import DataTable from '../global/DataTable';
import AlertComponent from '../global/AlertComponent';
import DynamicIcon from '../global/DynamicIcon'

const UserDetails = () => {
  const { userDetails, organizations, userError } = useSelector((state) => state.user || {});
  // Extract the initials for the avatar

  // Generate avatar initials if user details are present
  const avatarInitials = (userDetails && userDetails?.firstName && userDetails?.firstName.length > 0)
    ? `${userDetails?.firstName[0]}${userDetails?.lastName[0]}`
    : 'N/A';

  // Define columns
  const columns = useMemo(() => [
    {
      accessorKey: 'organizationName',
      header: 'Project Group',
      filterable: true,
      sortable: true,
      minSize: 10, size: 40,
      Cell: (params) => (
        <Typography>
          <Tooltip title={params.row.original.isDisabledOrg === 1 ? "Disabled" : "enabled"}>
            <span>
              <IconButton disabled sx={{ p: '2px' }}>
                {params.row.original.isDisabledOrg === 1 ? (
                  <DynamicIcon iconName="GppBad" sx={{ fontSize: '18px' }} />)
                  : (<DynamicIcon iconName="GppGood" sx={{ color: "green", fontSize: '18px' }} />)}
              </IconButton>
            </span>
          </Tooltip>
          <span style={{ marginLeft: '6px' }}>{params.row._valuesCache.organizationName}</span>
        </Typography>
      ),
    },
    {
      accessorKey: 'projectName',
      header: 'Project Name',
      filterable: true,
      sortable: true,
      minSize: 10, size: 40,
      Cell: (params) => (
        <Typography>
          <Tooltip title={params.row.original.isAdminProject === 1 && "Administrator"}>
            <span>
              <IconButton disabled sx={{ p: '2px' }}>
                {params.row.original.isAdminProject === 1 ? (
                  <DynamicIcon iconName="SettingsApplications" sx={{ color: "#e20074", fontSize: '18px' }} />
                ) : (
                  <DynamicIcon iconName="SettingsApplications" sx={{ color: "transparent", fontSize: '18px' }} />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={params.row.original.projectState === 1 ? "Disabled" : "enabled"}>
            <span>
              <IconButton disabled sx={{ p: '2px' }}>
                {params.row.original.projectState === 1 ? (
                  <DynamicIcon iconName="GppBad" sx={{ fontSize: '18px' }} />)
                  : (<DynamicIcon iconName="GppGood" sx={{ color: "green", fontSize: '18px' }} />)}
              </IconButton>
            </span>
          </Tooltip>
          <span style={{ marginLeft: '6px' }}>{params.row.original.projectName}</span>
        </Typography>
      ),
    },
    {
      accessorKey: 'roleName',
      header: 'Role Name',
      filterable: true,
      sortable: true,
      minSize: 10, size: 40,
      Cell: (params) => (
        <Typography>
          <Tooltip title={params.row.original.isAdminRole === 1 && "Administrator"}>
            <span>
              <IconButton disabled sx={{ p: '2px' }}>
                {params.row.original.isAdminRole === 1 ? (
                  <DynamicIcon iconName="SettingsApplications" sx={{ fontSize: '18px', color: "#e20074" }} />
                ) : (
                  <DynamicIcon iconName="SettingsApplications" sx={{ fontSize: '18px', color: "transparent" }} />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={params.row.original.roleState === 1 ? "Disabled" : "enabled"}>
            <span>
              <IconButton disabled sx={{ p: '2px' }}>
                {params.row.original.roleState === 1 ? (
                  <DynamicIcon iconName="GppBad" sx={{ fontSize: '18px' }} />
                ) : (
                  <DynamicIcon iconName="GppGood" sx={{ fontSize: '18px', color: "green" }} />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <span style={{ marginLeft: '6px' }}>{params.row.original.roleName}</span>
        </Typography>
      ),
    },
    {
      accessorKey: 'modify',
      header: 'Modify Role',
      sortable: false,
      minSize: 10, size: 20,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: (params) => (
        <IconButton sx={{ p: 0 }} disabled onClick={() => handleModify(params.row.id)}>
          <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
        </IconButton>
      ),
    },
    {
      accessorKey: 'delete',
      header: 'Delete',
      sortable: false,
      minSize: 10, size: 20,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: (params) => (
        <IconButton sx={{ p: 0 }} disabled onClick={() => handleDelete(params.row.id)}>
          <DynamicIcon iconName="DeleteForever" sx={{ fontSize: '18px' }} />
        </IconButton>
      ),
    },
  ], []);

  const handleModify = (row) => {
    console.log('Modify row:', row);
  };

  const handleDelete = () => {
    console.log("Delete Service Order clicked");
  };

  // Handler functions for buttons
  const handleUpdateOrganization = () => {
    console.log("Update Service Order clicked");
  };

  const handleDeleteOrganization = () => {
    console.log("Project Group clicked");
  };
  const joinNewOrganizations = () => {
    console.log("joinNewOrganizations clicked");
  }

  return (
    <Box>
      {userError?.reason ? (
        <AlertComponent
          fullWidth
          severity={userError?.severity}
          title={userError?.reason}
          description={userError?.message}
        />
      ) : (
        <Box>
          <Grid container spacing={2} >
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              {/* <Card sx={{  mt: 2, paddingTop: '0.8rem', boxShadow: 0 }}>
          <CardContent> */}
              <Grid container direction="column" alignItems="center" spacing={1}>
                {/* Avatar */}
                <Grid item>
                  <Avatar sx={{ bgcolor: '#7c7c7c', cursor: 'pointer', width: 50, height: 50, fontSize: '1.5rem' }}>
                    {avatarInitials}
                  </Avatar>
                </Grid>

                {/* User Details */}
                <Grid item sx={{ mt: 2 }}>
                  {userDetails ? (
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label="a dense table">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                              UUID
                            </TableCell>
                            <TableCell>{userDetails?.userId}</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                              First Name
                            </TableCell>
                            <TableCell>{userDetails?.firstName} </TableCell>
                            <TableCell>
                              <IconButton
                                sx={{ padding: 0 }}
                                disabled
                                onClick={() => handleModify(userDetails?.firstName)}
                              >
                                <DynamicIcon iconName="Edit" sx={{ fontSize: '18px' }} />
                              </IconButton >
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                              Last Name
                            </TableCell>
                            <TableCell>{userDetails?.lastName}</TableCell>
                            <TableCell>
                              <IconButton
                                sx={{ padding: 0 }}
                                disabled
                                onClick={() => handleModify(userDetails?.lastName)}
                              >
                                <DynamicIcon iconName="Edit" sx={{ fontSize: '18px' }} />
                              </IconButton >
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                              Email
                            </TableCell>
                            <TableCell>{userDetails?.email}</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                              Status
                            </TableCell>
                            <TableCell sx={{ py: 0}}>
                              <Typography>
                                {userDetails?.isDisabled === true ? (
                                  <Typography varient="subtitle1" component="span" sx={{fontSize: '14px', display: 'inline-flex' }}>
                                    <DynamicIcon iconName="GppBad" sx={{ fontSize: '18px', mr: 1}} /> 
                                    Disabled
                                  </Typography>
                                ) : (
                                  <Typography sx={{ display: 'inline-flex', fontSize: '14px' }}>
                                    <DynamicIcon iconName="GppGood" sx={{ color: "green", fontSize: '18px', mr: 1 }} />
                                    Enabled
                                  </Typography>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography variant="body1" color="error" align="center">
                      No user found
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Organizations Data Table */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Organizations
            </Typography>
            {organizations && organizations.length > 0 ? (
              <DataTable
                columns={columns}
                data={organizations}
                enableStickyHeader
                getRowId={(row) => row.id}
                showDownloadButton
                enableExpandAll={false}
                enableTopToolbar={true}
              />
            ) : (
              <Typography variant="body1" color="error" align="center">
                No organizations found
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'flex-column',
              justifyContent: 'end',
              padding: '12px',
              gap: '16px', 
              borderRadius: '8px'
            }}
          >
            {/* First flex div */}
            <Typography
              sx={{
                display: 'inline-flex',
                gap: '8px',
              }}
            >
              <DynamicIcon iconName="GppBad" sx={{ fontSize: '18px' }} />
              <Typography variant="subtitle2" component="span">
                Disabled
              </Typography>
            </Typography>

            <Typography
              sx={{
                display: 'inline-flex',
                gap: '8px',
              }}
            >
              <DynamicIcon iconName="GppGood" sx={{ fontSize: '18px', color: "green" }} />
              <Typography variant="subtitle2" component="span">
                Enabled
              </Typography>
            </Typography>
            <Typography
              sx={{
                display: 'inline-flex',
                gap: '8px',
              }}
            >
              <DynamicIcon iconName="SettingsApplications" sx={{ fontSize: '18px', color: "#e20074" }} />
              <Typography variant="subtitle2" component="span">
                Adminstrator
              </Typography>
            </Typography>

          </Box>
          <Grid container spacing={2} sx={{ mt: 3, mb: 3 }}>
            {/* Div 1: Update Service Order */}
            <Grid item xs={4} sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleUpdateOrganization}
                disabled
                size='large'
              >
                Update User Data
              </Button>
            </Grid>

            {/* Div 2: Delete Service Order */}
            <Grid item xs={4} sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={joinNewOrganizations}
                disabled
                size='large'
              >
                Join New Organization
              </Button>
            </Grid>
            {/* Div 2: Delete Service Order */}
            <Grid item xs={4} sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDeleteOrganization}
                disabled
                size='large'
              >
                Delete Your User
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default UserDetails;
