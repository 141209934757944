import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../lib/axios';

// Async thunk to fetch service instances
export const fetchServiceInstances = createAsyncThunk(
  'serviceInstance/fetchServiceInstances',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/service/service`);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw new Error('Network error: Failed to connect to the server');
      } else {
        return rejectWithValue(error?.response?.data);
      }
    } 
  }
);

// Async thunk to fetch service instance details
export const fetchServiceInstanceByID = createAsyncThunk(
  'serviceInstance/fetchServiceInstanceByID',
  async (selectedInstance, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/service/service/${selectedInstance}`);      
      if (response?.data?.statusCode === 403) {
        return rejectWithValue(response.data.error);
      }
      return response.data.body;

    } catch (error) {
      let jsonString = error.response?.data.replace(/'/g, '"');
      let errorObject = JSON.parse(jsonString);
      return rejectWithValue(errorObject || 'Failed to fetch service instance');
    }
  }
);

const serviceInstanceSlice = createSlice({
  name: 'serviceInstance',
  initialState: {
    serviceInstanceList: [],
    serviceInstanceById: [],
    serviceCharacteristic: [],
    serviceInstanceCount: 0,
    loading: false,
    servicError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle fetchServiceInstances lifecycle
    builder
      .addCase(fetchServiceInstances.pending, (state) => {
        state.loading = true;
        state.serviceError = null;
      })
      .addCase(fetchServiceInstances.fulfilled, (state, action) => {
        if (action.payload?.statusCode === 404 || action.payload?.statusCode === 401 || action.payload?.statusCode === 403) {
          const error = {
            ...action.payload?.error,
            severity: "warning"
          }
          state.serviceError = error || { message: 'Failed to fetch service Instances'};
        } else if (action.payload?.body && action.payload?.body.length === 0) {
          state.serviceError = {
            message: "No data found",
            reason: "Item Not Found",
            severity: "warning"
          };
        } else {
          state.serviceInstanceList = action.payload?.body.map((item) => {
            const csrProject = item.serviceCharacteristic.find(
              (characteristic) => characteristic.name === "csrProject"
            );
            return {
              ...item,
              csrProject: csrProject ? csrProject.value : null,
            }
          });
          state.serviceInstanceCount = action.payload.body.length || 0;
        }
        state.loading = false;
      })
      .addCase(fetchServiceInstances.rejected, (state, action) => {
        state.serviceError = { ...action.payload?.error,
          severity: "error"} || {
          message: "No data found",
          reason: "Item Not Found",
          severity: "error"};
        state.loading = false;
      });

    // Handle fetchServiceInstanceByID lifecycle
    builder
      .addCase(fetchServiceInstanceByID.pending, (state) => {
        state.loading = true;
        state.serviceError = null;
      })
      .addCase(fetchServiceInstanceByID.fulfilled, (state, action) => {
        state.serviceCharacteristic = action.payload[0].serviceCharacteristic.reduce((acc, char) => {
          acc[char.name] = char.value;
          return acc;
        }, {});
        // Extract serviceInstanceById with all top-level fields including csrProject
        state.serviceInstanceById = [{
          ...action.payload[0],
          csrProject: action.payload[0].serviceCharacteristic.find(item => item.name === 'csrProject')?.value
        }];
        state.loading = false;
      })
      .addCase(fetchServiceInstanceByID.rejected, (state, action) => {
        state.serviceError = action.payload || { message: 'Failed to fetch service order details' };
        state.loading = false;
      });
  },
});

// Export the slice reducer
export default serviceInstanceSlice.reducer;
