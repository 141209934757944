import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceOrders } from '../../store/serviceOrderSlice';
import { Box, Button, Typography, IconButton, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import DataTable from '../global/DataTable';
import DynamicIcon from '../global/DynamicIcon';
import AlertComponent from '../global/AlertComponent';

const useTableColumns = () => {
  return useMemo(() => [
    { 
      accessorKey: 'category', 
      header: 'Category', 
      filterable: true, 
      sortable: true, 
      minSize: 15, 
      size: 20 
    },
    { 
      accessorKey: 'id', 
      header: 'UUID', 
      filterable: true, 
      minSize: 7, 
      size: 15 
    },
    { 
      accessorKey: 'description', 
      header: 'Description', 
      filterable: true, 
      sortable: true, 
      minSize: 10, 
      size: 30 
    },
    { 
      accessorKey: 'notificationContact', 
      header: 'Notification Contact', 
      filterable: true, 
      sortable: true, 
      minSize: 10, 
      size: 20 
    },
    { 
      accessorKey: 'state', 
      header: 'State', 
      filterable: true, 
      minSize: 10, 
      size: 20 
    },
    { 
      accessorKey: 'externalId', 
      header: 'External ID', 
      filterable: true, 
      sortable: true, 
      minSize: 10, 
      size: 20 
    },
    {
      accessorKey: 'requestedStartDate',
      header: 'Requested Start Date',
      filterable: true,
      sortable: true,
      minSize: 10,
      size: 20,
      Cell: ({ row }) => (
        <Typography variant='body1'>
          {format(new Date(row._valuesCache.requestedStartDate), 'yyyy-MM-dd')}
        </Typography>
      ),
    },
    {
      accessorKey: 'details',
      header: 'Details',
      filterable: false,
      sortable: false,
      minSize: 5,
      size: 15,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: ({ row }) => (
        <IconButton>
          <Link to={`/serviceorder/${row.id}`}>
            <DynamicIcon iconName="Visibility" fontSize="medium" sx={{ color: "#e20074" }} />
          </Link>
        </IconButton>
      ),
    },
    {
      accessorKey: 'modify',
      header: 'Modify',
      sortable: false,
      minSize: 10,
      size: 15,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: () => (
        <IconButton disabled>
          <DynamicIcon iconName="EditOutlined" fontSize="medium" />
        </IconButton>
      ),
    },
    {
      accessorKey: 'delete',
      header: 'Delete',
      sortable: false,
      minSize: 10,
      size: 15,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: () => (
        <IconButton disabled>
          <DynamicIcon iconName="DeleteOutlined" fontSize="medium" />
        </IconButton>
      ),
    },
  ], []);
};

const ServiceOrderComponent = () => {
  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state.user);
  const { serviceOrderList = [], error, loading } = useSelector((state) => state.serviceOrder || {});
  
  const columns = useTableColumns();

  useEffect(() => {
    if (serviceOrderList.length === 0) {
      dispatch(fetchServiceOrders());
    }
  }, [dispatch, serviceOrderList.length]);

  const renderContent = () => {
    if (error?.reason) {
      return (
        <AlertComponent
          fullWidth
          severity={error.severity}
          title={error.reason}
          description={error.message}
        />
      );
    }

    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="secondary" />
        </Box>
      );
    }

    return (
      <DataTable
        columns={columns}
        data={serviceOrderList}
        enableStickyHeader
        getRowId={(row) => row.id}
        showDownloadButton
        showPrintButton
        enableExpandAll={false}
        enableTopToolbar={true}
      />
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ boxShadow: 3, borderRadius: 2, p: 2, overflow: 'hidden' }}>
        <Typography variant="h5">Service Orders List</Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'start', my: 2, gap: 2 }}>
          <Button 
            disabled={userRole !== 'Admin'} 
            variant="contained" 
            size="medium" 
            color="secondary"
          >
            <Link to="/createNew" style={{ color: 'inherit', textDecoration: 'none' }}>
              Create New Service Order
            </Link>
          </Button>
        </Box>

        {renderContent()}
      </Box>
    </Box>
  );
};

export default ServiceOrderComponent;