import { createSlice } from '@reduxjs/toolkit';
// import axiosInstance from '../lib/axios';


const digitalProviderSlice = createSlice({
  name: 'digitalProvider',
  initialState: {
    digitalServiceProviderList: [
        {
            uuid: '13e2wr',
            provider: 'Equinix',
            adminStatus: 1,
            infrastructureServices: 'Metal (enabled), Fabric Connection (enabled), Network Edge (disabled)',
        },
        {
            uuid: '13e2wrsqw',
            provider: 'Megaport',
            adminStatus: 0,
            infrastructureServices: 'Megaport Virtual Edge (disabled)',
        }
    ],
    loading: false,
  },
  reducers: {
    // Add synchronous reducers if needed
  },
  extraReducers: () => {
   
  },
});

// Export the slice reducer
export default digitalProviderSlice.reducer;
