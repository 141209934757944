import React, { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  IconButton,
  Button,
} from '@mui/material';
import DynamicIcon from '../global/DynamicIcon';

const SearchFilters = ({ setSearchCatalogue, searchCatalogue, filters, setFilters, handleCatalogueSearch, resetFilters }) => {
  const [infraServiceOptions, setInfraServiceOptions] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [availableLocationOptions, setAvailableLocationOptions] = useState([]);
  const [deploymentFlavourOptions, setDeploymentFlavourOptions] = useState([]);
  const { serviceTypes, enabledCatalogs } = useSelector((state) => state.serviceCatalog || {});

  // Helper function to get unique infra services
const getUniqueInfraServices = (catalogs) => {
  return [...new Set(
    catalogs.flatMap(item => 
      item.deliveryPoint.map(dp => dp.infraService)
    )
  )];
};

// Effect Infraturec
useEffect(() => {
  const catalogsToFilter = filters.serviceType 
    ? enabledCatalogs.filter(item => item.service_type === filters.serviceType)
    : enabledCatalogs;

  setInfraServiceOptions(getUniqueInfraServices(catalogsToFilter));
}, [filters.serviceType, enabledCatalogs]);

// Helper function to get unique Provider services
const getUniqueProviders = (catalogs) => {
  return [...new Set(
    catalogs.flatMap(item => 
      item.deliveryPoint.map(dp => dp.provider)
    )
  )];
};

 // Effect Provider
 useEffect(() => {
  const catalogsToFilter = filters.infrastructure 
  ? enabledCatalogs.filter(item => 
      item.deliveryPoint.some(dp => dp.infraService === filters.infrastructure)
    )
  : enabledCatalogs;

  setProviderOptions(getUniqueProviders(catalogsToFilter));
}, [filters.infrastructure, enabledCatalogs]);


// Helper function to get unique Provider services
const getAllLocations = (catalogs) => {
  return [...new Set(
    catalogs.flatMap(item => 
      item.deliveryPoint.map(dp => dp.availableLocation)
    )
  )];
};

 // Effect Available Locations
 useEffect(() => {
  const catalogsToFilter = filters.provider 
  ? enabledCatalogs.filter(item => 
      item.deliveryPoint.some(dp => dp.provider === filters.provider ||
        dp.infraService === filters.infrastructure)
    )
  : enabledCatalogs;

  setAvailableLocationOptions(getAllLocations(catalogsToFilter));
}, [filters.provider,filters.infrastructure, enabledCatalogs]);



// Helper function to get unique Provider services
const getMatchedDeploymentFlavours = (catalogs) => {
  return [...new Set(
    catalogs.flatMap(item => 
      item.nfSpecification.deploymentFlavour
    )
  )];
};

 // Effect Deployment Flavours Locations
 useEffect(() => {
  const catalogsToFilter = filters.location 
  ? enabledCatalogs.filter(item => 
      item.deliveryPoint.some(dp => dp.availableLocation === filters.location)
    )
  : enabledCatalogs;

  setDeploymentFlavourOptions(getMatchedDeploymentFlavours(catalogsToFilter));
}, [filters.location, enabledCatalogs]);


  // Handler to update a specific filter field
  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value
    }));
  };


  const handleClose = () => {
    setSearchCatalogue(!searchCatalogue);
  };

  return (
    <Box sx={{
      border: '1px solid #ccc',
      borderRadius: 1, p: 1, px:1.5
    }}>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1, gap:2 }}>
        <Button
          onClick={resetFilters}
          p={1}
          sx={{
            fontSize: 12
          }}
          color='secondary'
          >
            Reset Filters
        </Button>
        <IconButton
          onClick={handleClose}
          size="small"
          sx={{
            backgroundColor: '#D3D3D3', p: 0.5
          }}
        >
          <DynamicIcon iconName="Close" sx={{ fontSize: "20px", color: "black" }} />
        </IconButton>
      </Box>
      {/* Service Type Box */}
      <Box sx={{
        p: 1,
        mb: 0.5,
        display: 'flex',
        alignItems: 'center'
      }}>
        <Box sx={{ flex: 1 }}>
          {/* <Typography variant="body2" component="div" sx={{ mb: 1 }}>
            Service Type
          </Typography> */}
          <FormControl fullWidth size="small" sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
            {/* <InputLabel>Choose Service Type </InputLabel> */}
            <TextField
              select
              size='small'
              fullWidth
              value={filters.serviceType}
              InputLabelProps={{ shrink: true }}
              label="Select Service Type"
              onChange={(e) => handleFilterChange('serviceType', e.target.value)}
            >
              {serviceTypes && serviceTypes.length > 0 ? (
                serviceTypes.map((serviceType) => (
                  <MenuItem key={serviceType} value={serviceType}>
                    {serviceType}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Options found</MenuItem>
              )}
            </TextField>
          </FormControl>
        </Box>
      </Box>

      <Box
        sx={{
          p: 1,
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
            position: 'relative',
            mb: 1
        }}
      >
        {/* Name Filter Section */}
        <Box sx={{ flex: 1 }}>
          {/* <Typography variant="body2" component="div" sx={{ mb: 1 }}>
            Filter by Name
          </Typography> */}
          <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'flex-start' }}>
            <TextField
              label="Filter by Name"
              variant="outlined"
              size="small"
              value={filters.nameFilter}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleFilterChange('nameFilter', e.target.value)}
              fullWidth
            />
            <IconButton
              variant="contained"
              onClick={handleCatalogueSearch}
              sx={{ backgroundColor: "secondary.main" }}
              size='medium'
            >
              <DynamicIcon iconName="Search" sx={{ fontSize: "20px", color: 'white' }} />
            </IconButton>
          </Box>
        </Box>

        <Divider orientation="vertical" flexItem />

        {/* ID Filter Section */}
        <Box sx={{ flex: 1 }}>
          {/* <Typography variant="body2" component="div" sx={{ mb: 1 }}>
            Filter by ID
          </Typography> */}
          <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'flex-start' }}>
            <TextField
              label="Filter by Catalog ID"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              value={filters.idFilter}
              onChange={(e) => handleFilterChange('idFilter', e.target.value)}
              fullWidth
            />
            <IconButton
              variant="contained"
              onClick={handleCatalogueSearch}
              sx={{ backgroundColor: "secondary.main" }}
              size='medium'
            >
              <DynamicIcon iconName="Search" sx={{ fontSize: "20px", color: 'white' }} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Characteristics Section */}
      <Box sx={{ mb: 1 }}>
        <Divider>
          <Typography variant="body2" sx={{ px: 2 }}>
            Filter by Characteristics
          </Typography>
        </Divider>
      </Box>

      {/* Infrastructure and Provider */}
      <Box
        sx={{
          p: 1,
          display: 'flex',
          gap: 4,
          mb: 1
        }}
      >
        <Box sx={{ flex: 1 }}>
          {/* <Typography variant="body2" component="div" sx={{ mb: 1 }}>
            Infrastructure
          </Typography> */}
          <FormControl fullWidth size="small">
            <TextField
              select
              value={filters.infrastructure}
              InputLabelProps={{ shrink: true }}
              size='small'
              label="Select Infrastructure"
              onChange={(e) => handleFilterChange('infrastructure',e.target.value)}
            >
              {infraServiceOptions && infraServiceOptions.length > 0 ? (
                infraServiceOptions.map((infraServiceOptions) => (
                  <MenuItem key={infraServiceOptions} value={infraServiceOptions}>
                    {infraServiceOptions}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Options found</MenuItem>
              )}
            </TextField>
          </FormControl>
        </Box>

        <Divider orientation="vertical" flexItem />

        <Box sx={{ flex: 1 }}>
          {/* <Typography variant="body2" component="div" sx={{ mb: 1 }}>
            Provider
          </Typography> */}
          <FormControl fullWidth size="small">
            <TextField
              select
              value={filters.provider}
              InputLabelProps={{ shrink: true }}
              size='small'
              label="Select Provider"
              onChange={(e) => handleFilterChange('provider', e.target.value)}
              sx={{ height: 35 }}
            >
              {providerOptions && providerOptions.length > 0 ? (
                providerOptions.map((providerOptions) => (
                  <MenuItem key={providerOptions} value={providerOptions}>
                    {providerOptions}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Providers found</MenuItem>
              )}
            </TextField>
          </FormControl>
        </Box>

        <Divider orientation="vertical" flexItem />

        <Box sx={{ flex: 1 }}>
          {/* <Typography variant="body2" component="div" sx={{ mb: 1 }}>
            Locations
          </Typography> */}
          <FormControl fullWidth size="small">
            <TextField
              select
              value={filters.location}
              label="Select Location"
              InputLabelProps={{ shrink: true }}
              size='small'
              onChange={(e) => handleFilterChange('location',e.target.value)}
            >
              {availableLocationOptions && availableLocationOptions.length > 0 ? (
                availableLocationOptions.map((availableLocationOptions) => (
                  <MenuItem key={availableLocationOptions} value={availableLocationOptions}>
                    {availableLocationOptions}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No available Location found</MenuItem>
              )}
            </TextField>
          </FormControl>
        </Box>
      </Box>

      {/* Locations and Deployment Flavour */}
      <Box
        sx={{

          p: 1,
          display: 'flex',
          gap: 4,
          mb: 1
        }}
      >
        <Box sx={{ flex: 1 }}>
          {/* <Typography variant="body2" component="div" sx={{ mb: 1 }}>
            Deployment Flavour
          </Typography> */}
          <FormControl fullWidth size="small">
            <TextField
              select
              value={filters.deploymentFlavour}
              label="Select Deployment Flavour"
              InputLabelProps={{ shrink: true }}
              size='small'
              onChange={(e) => handleFilterChange('deploymentFlavour',e.target.value)}
            >
              {deploymentFlavourOptions && deploymentFlavourOptions.length > 0 ? (
                deploymentFlavourOptions.map((deploymentFlavourOptions) => (
                  <MenuItem key={deploymentFlavourOptions} value={deploymentFlavourOptions}>
                    {deploymentFlavourOptions}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No deployment flavour found</MenuItem>
              )}
            </TextField>
          </FormControl>
        </Box>


        <Divider orientation="vertical" flexItem />

        <Box sx={{ flex: 1 }}>
          {/* <Typography variant="body2" component="div" sx={{ mb: 1 }}>
            Service Term (Days)
          </Typography> */}
          <TextField
            fullWidth
            size="small"
            InputLabelProps={{ shrink: true }}
            label="Service Term (Days)"
            value={filters.serviceTerm}
            onChange={(e) => handleFilterChange('serviceTerm',e.target.value)}
            type="number"
          />
        </Box>

        <Divider orientation="vertical" flexItem />

        <Box sx={{ flex: 1 }}>
          {/* <Typography variant="body2" component="div" sx={{ mb: 1 }}>
            Interface Bandwidth (Mbps)
          </Typography> */}
          <TextField
            fullWidth
            size="small"
            label="Interface Bandwidth (Mbps)"
            value={filters.bandwidth}
            onChange={(e) => handleFilterChange('bandwidth',e.target.value)}
            type="number"
          />
        </Box>
      </Box>

      <Box sx={{ flex: 1, textAlign: 'center', mb: 0.3 }}>
        <IconButton
          variant="contained"
          onClick={handleCatalogueSearch}
          sx={{ backgroundColor: "secondary.main" }}
          size='medium'
        >
          <DynamicIcon iconName="Search" sx={{ fontSize: "20px", color: 'white' }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SearchFilters;