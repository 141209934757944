// createNewServiceOrder.js

import { createSlice } from '@reduxjs/toolkit';
import { formatInTimeZone } from 'date-fns-tz';

const currentDate = new Date();
const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0] + "T00:00:00.000Z";
const futureDate = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000);
// Function to format the date in UTC format
const formatDateToUTC = (date, time) => {
  const currentDate = new Date(Date.parse(date));
  const utcDate = currentDate.toISOString();
  if (!utcDate) return '';
  return formatInTimeZone(
    date,
    'UTC',
    `yyyy-MM-dd'T'${time}.SSS'Z'`
  );
};

const initialState = {
  formData: {
    category: '',
    description: '',
    externalId: '',
    notificationContact: '',
    requestedStartDate: new Date(currentDate.getTime() + 1 * 60 * 60 * 1000).toISOString(),
    requestedCompletionDate:  formatDateToUTC(futureDate, '23:59:00'),
    serviceOrderItem: [{
      action: 'add',
      quantity: 1,
      service: {
        serviceType: '',
        name: '',
        category: 'PIU',
        startDate: tomorrow,
        endDate: null,
        serviceCharacteristic: [
          {
            name: "provider",
            value: ""
          },
          {
            name: "location",
            value: "",
          },
          {
            name: "csrProject",
            value: "",
          },
          {
            name: "infraService",
            value: "",
          },
          {
            name: "internetAccess_type",
            value: "default",
          },
          {
            name: "internetAccess_zPortId",
            value: "",
          },
          {
            name: "internetAccess_zVLan",
            value: 0,
          },
          {
            name: "internetAccess_bandwidth",
            value: 0,
          },
          {
            name: "internetAccess_serviceProviderName",
            value: "",
          },
          {
            name: "internetAccess_serviceProviderId",
            value: "",
          },
          {
            name: "internetAccess_portLocation",
            value: "",
          },
          // INTRANET
          {
            name: "intranetAccess_zPortId",
            value: null,
          },
          {
            name: "intranetAccess_zVLan",
            value: null,
          },
          {
            name: "intranetAccess_bandwidth",
            value: null,
          },
          {
            name: "intranetAccess_serviceProviderId",
            value: "",
          },
          {
            name: "intranetAccess_portLocation",
            value: "",
          },
          {
            name: "intranetAccess_serviceProviderName",
            value: "",
          }
        ],
        serviceSpecification: {
          id: ''
        },
      }
    }]
  },
  activeStep: 0,
  checkFormType: 'wizard'
};

const createNewServiceOrder = createSlice({
  name: 'createNewServiceOrder',
  initialState,
  reducers: {
    setCheckFormType: (state, action) => {
      state.checkFormType = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    resetFormData: (state) => {
      state.formData = initialState.formData;
    },
    // Add methods for updating serviceSpecification and serviceCharacteristic

    // Update serviceSpecification method
    setServiceSpecification: (state, action) => {      
      const { value } = action.payload;
      state.formData.serviceOrderItem[0].service.serviceSpecification.id = value;
    },

    // Update specific serviceCharacteristic
    setServiceCharacteristic: (state, action) => {
      const { field, value } = action.payload;
            
      const characteristicIndex = state.formData.serviceOrderItem[0].service.serviceCharacteristic.findIndex(
        characteristic => characteristic.name === field
      );
      if (characteristicIndex !== -1) {
        state.formData.serviceOrderItem[0].service.serviceCharacteristic[characteristicIndex].value = value;
      }
    },
    updateServiceField: (state, action) => {
      const { field, value } = action.payload;      
      state.formData.serviceOrderItem[0].service[field] = value;      
    }
  }
});

export const { setFormData, setActiveStep, resetFormData, setServiceSpecification,
  setServiceCharacteristic, updateServiceField, setCheckFormType } = createNewServiceOrder.actions;
export default createNewServiceOrder.reducer;
