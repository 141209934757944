import React, { useMemo } from 'react';
import { Typography, Box, Tooltip, IconButton, Button } from '@mui/material';
import DynamicIcon from './global/DynamicIcon';
import DataTable from './global/DataTable';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DigitalProvider = () => {
  const navigate = useNavigate();
  const { digitalServiceProviderList } = useSelector((state) => state.digitalProvider);
  const columns = useMemo(() => [
    // {
    //   accessorKey: 'uuid',
    //   header: 'UUID',
    //   filterable: true,
    //   sortable: true,
    //   minSize: 10, size: 20,
    // },
    {
      accessorKey: 'provider',
      header: 'Provider',
      filterable: true,
      sortable: true,
      minSize: 10, size: 15,
    },
    {
      accessorKey: 'adminStatus',
      header: 'Admin Status',
      filterable: true,
      sortable: true,
      minSize: 10, size: 12,
      Cell: (params) => (
        <Typography alignContent="center">
          <Tooltip title={params.row.original.adminStatus === 1 ? "enabled": "Disabled"}>
            <span>
              <IconButton disabled sx={{ p: '2px' }}>
                {params.row.original.adminStatus === 1 ? (
                  <DynamicIcon iconName="GppGood" sx={{ fontSize: '18px', color: "green"  }} />
                ) : (
                  <DynamicIcon iconName="GppBad" sx={{fontSize: '18px', color: "black" }} />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Typography>
      ),
    },
    {
      accessorKey: 'infrastructureServices',
      header: 'Infrastructure Services',
      filterable: true,
      sortable: true,
      minSize: 10, size: 25,
    },
    // {
    //   accessorKey: 'details',
    //   header: 'Details',
    //   enableColumnFilter: false,
    //   filtering: false,
    //   sortable: false,
    //   minSize: 5, size: 8,
    //   enableColumnActions: false,
    //   Cell: (params) => (
    //     <IconButton>
    //       <DynamicIcon iconName="Visibility" sx={{ color: "#e20074", fontSize: '18px' }} />
    //     </IconButton>
    //   ),
    // },
    {
      accessorKey: 'modify',
      header: 'Modify',
      sortable: false,
      minSize: 5, size: 8,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: (params) => (
        <IconButton sx={{ p: 0 }} disabled onClick={() => handleModify(params.row.id)}>
          <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
        </IconButton>
      ),
    },
    {
      accessorKey: 'delete',
      header: 'Delete',
      sortable: false,
      minSize: 5, size: 8,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: (params) => (
        <IconButton sx={{ p: 0 }} disabled onClick={() => handleDelete(params.row.id)}>
          <DynamicIcon iconName="DeleteForever" sx={{ fontSize: '18px' }} />
        </IconButton>
      ),
    },
  ], []);

  const handleModify = (row) => {
    console.log('Modify row:', row);
  };

  const handleDelete = () => {
    console.log("Delete Service Order clicked");
  };

  return (
    <Box>
      <Button sx={{ mb: 2 }} onClick={() => navigate(-1)} variant="outlined" color="secondary">
        <DynamicIcon iconName="ArrowBack" sx={{ fontSize: '18px'}} />
      </Button>
      <Box sx={{ boxShadow: 3, borderRadius: 2, p: 2, overflow: 'hidden' }}>
        <Typography variant="h5" sx={{ mb: 2}}>Digital Service Provider</Typography>
        <DataTable
          columns={columns}
          data={digitalServiceProviderList}
          enableStickyHeader
          getRowId={(row) => row.id}
          enableExpandAll={false}
          enableTopToolbar={false}
        />
      </Box>
    </Box>
  );
};

export default DigitalProvider;