import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceInstances } from '../../store/serviceInstanceSlice';
import { Box, Button, Typography, IconButton, CircularProgress, Tooltip } from '@mui/material';
import DataTable from '../global/DataTable';
import ServiceInstancesDetails from './ServiceInstancesDetails';
import { Link } from 'react-router-dom';
import DynamicIcon from '../global/DynamicIcon'
import AlertComponent from '../global/AlertComponent';
import { format } from 'date-fns';

const ServiceInstanceComponent = () => {
  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state.user);
  const { serviceInstanceList = [], serviceError, loading } = useSelector((state) => state.serviceInstance || {});  

  const [selectedInstance, setSelectedInstance] = useState('');

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'UUID', filterable: true, minSize: 7, size: 15,
      Cell: (params) => (
        <Tooltip title={params.row.id}>
          <span>{params.row.id}</span>
        </Tooltip>
      ),
     },
    { accessorKey: 'name', header: 'Instance Name', filterable: true, sortable: true, minSize: 10, size: 20 },
    { accessorKey: 'state', header: 'State', filterable: true, minSize: 10, size: 20},
    { accessorKey: 'startDate', header: 'Start Date', filterable: true, sortable: true, minSize: 10, size: 20,
      Cell: (params) => {
        return (
          <Typography variant='body1'>
            {format(new Date(params.row._valuesCache.startDate), 'yyyy-MM-dd')}
          </Typography>
        );
      },
     },
     { accessorKey: 'csrProject', header: 'Customer Project', filterable: true, sortable: true, minSize: 10, size: 20 },
    {
      accessorKey: 'details',
      header: 'Details',
      enableColumnFilter: false,
      filtering: false,
      sortable: false,
      minSize: 5, size: 15,
      enableColumnActions: false,
      Cell: (params) => (
        <IconButton>
          <Link to={`/serviceinstance/${params.row.id}`}>
            <DynamicIcon iconName="Visibility" fontSize="medium" sx={{ color: "#e20074" }} />
          </Link>
        </IconButton>
      ),
    },
    {
      accessorKey: 'modify',
      header: 'Modify',
      sortable: false,
      minSize: 10, size: 15,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: (params) => (
        <IconButton disabled onClick={() => handleModify(params.row.id)}>
          <DynamicIcon iconName="EditOutlined" fontSize="medium" />
        </IconButton>
      ),
    },
    {
      accessorKey: 'delete',
      header: 'Delete',
      sortable: false,
      minSize: 10, size: 15,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: (params) => (
        <IconButton disabled onClick={() => handleDelete(params.row.id)}>
          <DynamicIcon iconName="DeleteOutlined" fontSize="medium" />
        </IconButton>
      ),
    },
  ], []);
  

  // const handleDetails = (id) => {
  //   setSelectedInstance(id);
  // };

  const handleModify = (row) => {
    console.log('Modify row:', row);
  };
  
  const handleDelete = (row) => {
    console.log('Delete row:', row);
  };

  useEffect(() => {
    // Fetch service orders when the component mounts  
    if(serviceInstanceList && serviceInstanceList.length === 0) {
      dispatch(fetchServiceInstances());
    }  
  }, [dispatch, serviceInstanceList]);

  const handleSelectedInstance = (serviceId) => {
    setSelectedInstance(serviceId);
  };

  const handleCreateNewserviceInstance = () => {
    setSelectedInstance('');
    // setCreateNewserviceInstance(true);
  };

  // const handleCloseNewserviceInstance = () => {
  //   // setCreateNewserviceInstance(false);
  // };  
  
  return (
    <Box>
      {/* Show welcome message when no order is selected and not creating a new service order */}
        <Box sx={{ mt: 2 }}>
        {selectedInstance ? (
            // Display service order details if an order is selected
            <ServiceInstancesDetails selectedInstance={selectedInstance} setSelectedInstance={handleSelectedInstance} />
          ) : (
            <Box sx={{ boxShadow: 3, borderRadius: 2, p: 2, overflow: 'hidden' }}>
              <Typography variant="h5">Service Instances List</Typography>
  
              {/* Button to create a new service order */}
              {!selectedInstance && (
                <Box sx={{ display: 'flex', justifyContent: 'start', my: 2, gap:2 }}>
                  <Button disabled={userRole !== 'Admin'} onClick={handleCreateNewserviceInstance} variant="contained" size="medium" color="secondary">
                    <Link to="/createNew" style={{ color: 'inherit', textDecoration: 'none' }}>
                      Create New Service Instance
                    </Link>
                  </Button>
                </Box>
              )}
  
              {/* Handle serviceError, loading, or display the data table */}
              {serviceError?.reason ? (
                <AlertComponent
                  fullWidth
                  severity={serviceError.severity}
                  title={serviceError.reason}
                  description={serviceError.message}
                />
              ) : loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress color="secondary" />
                </Box>
              ) : (
                <DataTable
                  columns={columns}
                  data={serviceInstanceList}
                  enableStickyHeader
                  getRowId={(row) => row.id}
                  showDownloadButton
                  showPrintButton
                  enableExpandAll={false}
                  enableTopToolbar={true}
                />
              )}
            </Box>
          )}
        </Box>
      {/* )} */}
    </Box>
  );
};

export default ServiceInstanceComponent;
